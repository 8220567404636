import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import CloudUpload from '@mui/icons-material/CloudUpload';
import useGlobal from 'global-state/store';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useFirestore } from 'reactfire';
import { doc, updateDoc } from 'firebase/firestore';
import validationSchema from './validationSchema';
import CertificateInfoInputs from '../creation/CertificateInfoInputs';

export default function UpdateCertificateInfoForm({
  type, gaugerOrganizationName, existingCertificate,
}) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const [uploading, setuploading] = useState(false);
  const navigate = useNavigate();
  const acceptedHeightUnits = ['m'];
  const acceptedVolumeUnits = ['m3'];
  const firestore = useFirestore();

  const initialFormValues = () => {
    const defaultValues = {
      certificateId: existingCertificate.certificateId,
      ownerOrganizationName: existingCertificate.ownerOrganizationName,
      gaugerOrganizationName,
      tankId: existingCertificate.tankId,
      cellarName: existingCertificate.cellarName ? existingCertificate.cellarName : '',
      expirationDate: moment.unix(existingCertificate.expirationDate.seconds),
      gaugeDate: moment.unix(existingCertificate.gaugeDate.seconds),
    };
    return defaultValues;
  };

  const formik = useFormik({
    initialValues: initialFormValues(),
    validationSchema: validationSchema(t),
    onSubmit(values) {
      return submit(values);
    },
  });

  async function updateFirestoreDocument(values) {
    const certificateRef = doc(
      firestore,
      'organizations',
      globalState.activeOrganization,
      'apps',
      'digitank-gauger',
      'gaugerCertificates',
      values.certificateId,
    );

    await updateDoc(certificateRef, {
      ownerOrganizationName: values.ownerOrganizationName,
      gaugerOrganizationName: values.gaugerOrganizationName,
      tankId: values.tankId,
      cellarName: values.cellarName,
      expirationDate: values.expirationDate.toDate(),
      gaugeDate: values.gaugeDate.toDate(),
    });
  }

  async function submit(values) {
    setuploading(true);
    try {
      await updateFirestoreDocument(values);
      globalActions.setSnackbarMessage({ message: t('success'), severity: 'success' });
      navigate(-1);
    } catch (e) {
      globalActions.setSnackbarMessage({ message: t('unexpected_error'), severity: 'error' });
    } finally {
      setuploading(false);
    }
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 20,
      }}
    >
      <Typography variant="h5" component="div" sx={{ mt: 2 }}>
        {`${t(`type.${existingCertificate.type}`)} : ${t('admin.update_info')}`}
      </Typography>

      <CertificateInfoInputs
        context="updateInfo"
        type={existingCertificate.type}
        formik={formik}
        acceptedHeightUnits={acceptedHeightUnits}
        acceptedVolumeUnits={acceptedVolumeUnits}
      />
      <LoadingButton
        loading={uploading}
        sx={{
          maxWidth: 200, alignSelf: 'center', mt: 5, mb: 5,
        }}
        variant="contained"
        type="submit"
        size="large"
        disabled={formik.isSubmitting || !formik.isValid}
        endIcon={<CloudUpload />}
      >
        {t('certificate_creation.upload_csv')}
      </LoadingButton>
    </form>
  );
}
