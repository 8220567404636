import React from 'react';
import useGlobalHook from './useGlobalHook';
import * as actions from './actions';

const initialState = {
  cookiesAccepted: false,
  userStatus: 'initial',
  globalSnackbar: { display: false, mesage: '', severity: 'info' },
  activeOrganization: '',
  roles: [],
  transporterNamesSearched: false,
  transporterNames: {},
  clientOrganizations: {}, // dictionary of org names by id
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
