import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import FileDropzone from 'components/FileDropzone';
import Button from '@mui/material/Button';
import DescriptionIcon from '@mui/icons-material/Description';
import Papa from 'papaparse';
import modelTTDataFr from 'staticFiles/modele-donnees-camion-citerne.csv';
import modelGCDataFr from 'staticFiles/modele-donnees-contenant-jauge.csv';

export default function CertificateGaugingDataDropZone(props) {
  const {
    formik, errorHandler, aborthandler, type,
  } = props;
  const { t } = useTranslation();

  function getExample() {
    if (type === 'tanker_truck') {
      return modelTTDataFr;
    }
    return modelGCDataFr;
  }

  const callback = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onerror = errorHandler;
    reader.onabort = aborthandler;
    reader.onload = (e) => {
      // Only parsing the first line just to get the headers
      const parsedCsv = Papa.parse(reader.result, {
        header: false,
        dynamicTyping: true,
        skipEmptyLines: true,
        preview: 1,
        delimitersToGuess: [';', ','],
      });
      const firstRow = parsedCsv.data[0];
      const hasHeaders = firstRow.includes('hauteur') || firstRow.includes('volume');

      let updatedCSVData = reader.result;

      if (!hasHeaders) {
        const headerLine = 'hauteur;volume\n';
        updatedCSVData = headerLine + reader.result;
      }
      if (type === 'tanker_truck') {
        formik.setFieldValue(
          'nominalVolumesNamesFromData',
          hasHeaders ? firstRow.filter((name) => name !== 'hauteur') : ['volume'],
        );
      }

      formik.setFieldValue('compartmentsData', updatedCSVData.trim());
      formik.setFieldValue('dataFileName', file.path);
      setTimeout(() => formik.validateForm(), 0);
    };
    reader.readAsText(file);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 2,
        mt: 5,
      }}
    >
      <Typography variant="h5" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('certificate_creation.tank_data_dropzone')}
      </Typography>
      <FileDropzone
        text={t('certificate_creation.upload_csv_bar_text')}
        callback={callback}
        accept={{ 'application/octet-stream': ['.bar'], 'text/csv': ['.csv'] }}
        fileName={formik.values.dataFileName}
      />
      {formik.errors.compartmentsData && (<Typography sx={{ color: 'red' }}>{t('validations.required')}</Typography>)}
      <a href={getExample()} style={{ textDecoration: 'none' }} download>
        <Button
          variant="outlined"
          component="span"
          startIcon={<DescriptionIcon />}
        >
          {t('menu_boxettes.download_model_CSV')}
        </Button>
      </a>
    </Box>
  );
}
