import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import AccountBox from '@mui/icons-material/AccountBox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useMenu from 'menu-actions/useMenu';
import LanguageSelect from 'components/LanguageSelect';
import logofr from 'staticFiles/logo-gc-fr.svg';
import logoen from 'staticFiles/logo-gc-en.svg';

export default function TopButtons(props) {
  const { menuActions } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function displayAccountButton() {
    return !location.pathname.includes('account')
      && !location.pathname.includes('pdfViewer')
      && !location.pathname.includes('volumeConversion')
      && !location.pathname.includes('signin')
      && !location.pathname.includes('cgu')
      && !location.pathname.includes('contact');
  }

  function displayMenu() {
    return (location.pathname.includes('operation') && !location.pathname.includes('admin'))
    || location.pathname.includes('pdfViewer');
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: 1,
      }}
    >
      <LogoOrReturn />
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {displayAccountButton() && (
          <IconButton
            aria-label="account"
            component={Link}
            to="account"
            sx={{ p: 0, fontSize: 37 }}
            color="primary"
          >
            <AccountBox fontSize="inherit" />
          </IconButton>
        )}
        {displayMenu() && (
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={menuOpen ? 'long-menu' : undefined}
            aria-expanded={menuOpen ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
        )}
        <MyMenu
          anchorEl={anchorEl}
          handleMenuClose={handleMenuClose}
          menuOpen={menuOpen}
          actions={menuActions}
        />
        {!displayMenu() && <LanguageSelect />}
      </Box>
    </Box>
  );
}

function LogoOrReturn() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { returnAction, setreturnAction } = useMenu();

  const logo = () => (t('locale') === 'fr' ? logofr : logoen);

  React.useEffect(() => {
    setreturnAction(() => () => navigate(-1));
  }, [location, navigate, setreturnAction]);

  if (['/signin'].includes(location.pathname)) {
    return (
      <img
        src={logo()}
        style={{ height: 40, objectFit: 'cover' }}
        alt="GC Labox"
      />
    );
  }

  if (['/'].includes(location.pathname)) {
    return (
      <img
        src={logo()}
        style={{ height: 40, objectFit: 'cover' }}
        alt="GC Labox"
      />
    );
  }
  return (
    <IconButton size="large" onClick={returnAction}>
      <ArrowBack />
    </IconButton>
  );
}

function MyMenu(props) {
  const {
    anchorEl, handleMenuClose, menuOpen, actions,
  } = props;

  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={handleMenuClose}
    >
      {Object.entries(actions).map((action) => (
        <MenuItem key={action[0]} onClick={() => { action[1](); handleMenuClose(); }}>
          {action[0]}
        </MenuItem>
      ))}
    </Menu>
  );
}
