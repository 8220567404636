import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';

export default function GaugingCurve({ allHeights, compartmentsDictionaries }) {
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  );

  const options = {
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: t('certificate_creation.volume_and_unit'),
        },
      },
      x: {
        title: {
          display: true,
          text: t('certificate_creation.height_and_unit'),
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label(context) {
            const label = context.dataset.label || '';
            if (label) {
              return `${label}: ${context.parsed.y.toLocaleString('en-US', { useGrouping: false })}`;
            }
            return context.parsed.y.toLocaleString('en-US', { useGrouping: false });
          },
        },
      },
    },
  };

  const colors = ['#437b89', '#f4ae43', '#99bd5b', '#000000', '#4d90fe', '#49d555', '#cb7272', '#d549c0', '#d5cf49'];
  const minAllHeights = Math.min(...compartmentsDictionaries.map((comp) => comp.minHeight));
  const maxAllHeights = Math.max(...compartmentsDictionaries.map((comp) => comp.maxHeight));
  const trimmedHeights = allHeights.filter((height) => (height >= minAllHeights && height <= maxAllHeights));

  const errorMessages = [];

  compartmentsDictionaries.forEach((compartment) => {
    trimmedHeights.forEach((height) => {
      const volume = compartment.heightToVolume[height];
      if (typeof height !== 'number') {
        errorMessages.push(`Compartiment ${compartment.id}: valeur hauteur '${height}' n'est pas un nombre.`);
      } else if (typeof volume !== 'number') {
        errorMessages.push(
          `Compartiment ${compartment.id}: valeur volume '${volume}' pour hauteur '${height}' n'est pas un nombre.`,
        );
      }
    });
  });

  const data = {
    labels: trimmedHeights,
    datasets: compartmentsDictionaries.map((compartment, i) => ({
      label: compartment.id,
      data: trimmedHeights.map((height) => compartment.heightToVolume[height] || 0),
      borderColor: colors[i],
      backgroundColor: colors[i],
    })),
  };

  return (
    <>
      <Line options={options} data={data} />
      {errorMessages.length > 0 && (
      <div style={{ color: 'red' }}>
        <h2>Error Messages:</h2>
        <ul>
          {errorMessages.map((errorMessage, index) => (
            <li key={errorMessage}>{errorMessage}</li>
          ))}
        </ul>
      </div>
      )}
    </>
  );
}
