import React, { useState } from 'react';
import {
  Select, MenuItem, InputLabel, FormControl,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function OrderBySelect({ updateCallback = () => {} }) {
  const { t } = useTranslation();
  const options = ['lastModified', 'expirationDate', 'certificateId'];
  const optionNames = options.reduce((acc, key) => {
    acc[key] = t(`orderby.${key}`);
    return acc;
  }, {});

  const [activeOption, setActiveOption] = useState(options[0]);

  const handleChange = async (event) => {
    const newActiveType = event.target.value;
    setActiveOption(newActiveType);
    updateCallback(newActiveType);
  };

  return (
    <FormControl sx={{ minWidth: 170 }}>
      <InputLabel id="order-by-select-label">{t('order_by')}</InputLabel>
      <Select
        labelId="order-by-select-label"
        displayEmpty
        size="large"
        label={t('order_by')}
        value={activeOption}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {optionNames[option]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
