import React from 'react';
import { useFirestore, useFirestoreDocDataOnce } from 'reactfire';
import {
  doc,
} from 'firebase/firestore';
import useGlobal from 'global-state/store';
import { Box, CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import UpdateCertificateInfoForm from './UpdateCertificateInfoForm';

export default function CertificateUpdateInfo() {
  const [globalState] = useGlobal();
  const location = useLocation();
  const { type, certificate: existingCertificate } = location.state;

  if (globalState.activeOrganization !== '') {
    return (
      <CertificateUpdateInfoContent
        type={type}
        activeOrg={globalState.activeOrganization}
        existingCertificate={existingCertificate}
      />
    );
  }

  return (
    <Box
      sx={{
        height: '75vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}

function CertificateUpdateInfoContent({
  type, activeOrg, existingCertificate,
}) {
  const firestore = useFirestore();

  const appDocRef = doc(
    firestore,
    `organizations/${activeOrg}`,
  );

  const { status: activeOrgStatus, data: activeOrgData } = useFirestoreDocDataOnce(appDocRef);

  if (activeOrgStatus === 'success') {
    return (
      <UpdateCertificateInfoForm
        type={type}
        gaugerOrganizationName={activeOrgData.name}
        existingCertificate={existingCertificate}
      />
    );
  }

  return (
    <Box
      sx={{
        height: '75vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
