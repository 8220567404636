import React, { useState } from 'react';
import {
  Select, MenuItem, InputLabel, FormControl, Box, Chip, CircularProgress, Checkbox, ListItemText,
} from '@mui/material';
import useGlobal from 'global-state/store';
import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { useTranslation } from 'react-i18next';

export default function OwnerNamesSelect({
  updateCallback = () => {},
}) {
  const [globalState] = useGlobal();
  const [activeOrganizations, setActiveOrganizations] = useState([]);
  const firestore = useFirestore();
  const { t } = useTranslation();

  const organizationAppRef = doc(
    firestore,
    `organizations/${globalState.activeOrganization}/apps/digitank-gauger`,
  );

  const {
    status: organizationAppStatus,
    data: organizationAppData,
  } = useFirestoreDocData(organizationAppRef);

  if (organizationAppStatus !== 'success') {
    return <CircularProgress />;
  }

  const ownerNames = organizationAppData.certificateOwnerNames || [];

  const handleOrganizationChange = async (event) => {
    const val = event.target.value;
    const newActiveOrganizations = typeof val === 'string' ? val.split(',') : val;
    setActiveOrganizations(newActiveOrganizations);
    updateCallback(newActiveOrganizations);
  };

  return (
    <FormControl sx={{ minWidth: 170 }}>
      <InputLabel id="client-select-label">{`${t('owner')}s`}</InputLabel>
      <Select
        labelId="client-select-label"
        displayEmpty
        multiple
        size="large"
        label={`${t('owner')}s`}
        value={activeOrganizations}
        onChange={handleOrganizationChange}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} size="small" label={value} />
            ))}
          </Box>
        )}
      >
        {ownerNames.map((organizationName) => (
          <MenuItem key={organizationName} value={organizationName}>
            <Checkbox checked={activeOrganizations.indexOf(organizationName) > -1} />
            <ListItemText primary={organizationName} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
