import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { useNavigate } from 'react-router-dom';

export default function TankDetail(props) {
  const {
    open, setOpen, tank, tankPath, setTanks,
  } = props;
  const { t } = useTranslation();
  const [openWarning, setopenWarning] = React.useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    setopenWarning(false);
  };

  const handleUpdate = () => {
    navigate('create', {
      state: {
        update: true,
        tankId: tank.id,
        cellar: tank.cellar,
        heightUnit: 'm',
        volumeUnit: 'm3',
        capacity: tank.capacity,
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {t('tank_selection.tank')}
      </DialogTitle>
      <DialogContent>
        <TankContent tank={tank} tankPath={tankPath} />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setopenWarning(true)}>
          {t('delete')}
        </Button>
        <Button variant="outlined" onClick={handleUpdate}>
          {t('admin.new_gauging')}
        </Button>
        <Button onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
      <TankWarningDelete
        setTanks={setTanks}
        tankPath={tankPath}
        tank={tank}
        openWarning={openWarning}
        handleClose={handleClose}
        setopenWarning={setopenWarning}
      />
    </Dialog>
  );
}

function TankContent(props) {
  const { t } = useTranslation();
  const { tank, tankPath } = props;
  const navigate = useNavigate();
  const db = useFirestore();
  const tankRef = doc(db, tankPath);
  const [certificateExpirationDate, setCertificateExpirationDate] = React.useState('');

  React.useEffect(() => {
    async function getCertificate() {
      const tankDoc = await getDoc(tankRef);
      const tankData = tankDoc.data();
      const certifRef = doc(db, tankDoc.ref.path, 'gaugeCertificates', tankData.activeCertificate);
      const certifDoc = await getDoc(certifRef);
      const certifData = certifDoc.data();
      setCertificateExpirationDate(new Date(certifData.expirationDate).toLocaleDateString());
    }

    if (certificateExpirationDate === '') {
      getCertificate();
    }
  }, [certificateExpirationDate, db, tankRef]);

  const handleGoToPDFViewer = () => {
    // eslint-disable-next-line max-len
    navigate(`/pdfViewer?filePath=${encodeURIComponent(`gs://labox-ws.appspot.com/${tankPath}/gaugeCertificates/${tank.activeCertificate}.pdf`)}`);
  };

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', gap: 3, width: '100%',
      }}
    >
      <Typography variant="body2">
        {t('tank_selection.cellar')}
        :
        {' '}
        {tank.cellar}
        <br />
        {t('tank_selection.tank')}
        :
        {' '}
        {tank.id}
        <br />
        {t('tank_selection.certificate')}
        :
        {' '}
        {tank.activeCertificate}
        <br />
        {t('tank_selection.valid_until')}
        :
        {' '}
        {certificateExpirationDate}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button
          onClick={handleGoToPDFViewer}
          size="small"
          variant="contained"
          color="primary"
        >
          {t('storage.certificate_pdf')}
        </Button>
      </Box>
    </Box>
  );
}

function TankWarningDelete(props) {
  const db = useFirestore();
  const { t } = useTranslation();
  const {
    handleClose, setTanks, openWarning, tankPath, tank, setopenWarning,
  } = props;

  const handleDelete = async () => {
    await deleteDoc(doc(db, tankPath));
    setTanks((prevState) => [...prevState.filter((atank) => atank.id !== tank.id)]);
    handleClose();
  };

  const handleThisClose = () => {
    setopenWarning(false);
  };

  return (
    <Dialog
      open={openWarning}
      onClose={handleThisClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 3,
        }}
      >
        <Typography variant="body2">{t('admin.tank_warning_deletion')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleDelete}>
          {t('delete')}
        </Button>
        <Button onClick={handleThisClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
