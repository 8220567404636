import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useFirestore } from 'reactfire';
import getTransporterIdsAndTankIds from 'components/tankSelection/getTransporterIdsAndTankIds';

export default function TransporterSelect({
  selectCallback, isTransporterValid, clientOrg,
}) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const [transporters, setTransporters] = useState([]);
  const [pageStatus, setPageStatus] = useState('initial');
  const [currentTransporter, setCurrentTransporter] = useState('');
  const firestore = useFirestore();

  const handleChange = useCallback((newTransporter) => {
    setCurrentTransporter(newTransporter);
    selectCallback(newTransporter);
  }, [selectCallback]);

  const getTransporters = useCallback(async () => {
    let newTransporterNames;
    if (globalState.transporterNamesSearched === false) {
      const newTransportersAndTanks = await getTransporterIdsAndTankIds(
        firestore,
        clientOrg,
      );
      const newTransporterNamesWithOrgs = Object.keys(newTransportersAndTanks);
      globalActions.setTransporterNames(clientOrg, newTransporterNamesWithOrgs);
      newTransporterNames = newTransporterNamesWithOrgs.map((item) => item.split('::')[1]);
    } else {
      newTransporterNames = globalState.transporterNames[clientOrg].map((item) => item.split('::')[1]);
    }
    setTransporters(newTransporterNames);

    setPageStatus('loaded');
  }, [clientOrg, firestore, globalActions,
    globalState.transporterNames, globalState.transporterNamesSearched]);

  useEffect(() => {
    if (pageStatus === 'initial' && globalState.activeOrganization !== '') {
      setPageStatus('loading');
      getTransporters();
    }
  }, [getTransporters, globalState.activeOrganization, pageStatus]);

  return (
    <>
      {pageStatus === 'loaded' && (
        <Autocomplete
          freeSolo
          size="small"
          sx={{ flexGrow: 1 }}
          options={transporters}
          value={currentTransporter}
          onChange={(event, value) => handleChange(value)}
          renderInput={(params) => (
            <TextField
              label={t('tank_selection.transporter')}
              inputProps={{ maxLength: 30 }}
              onBlur={() => handleChange(params.inputProps.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  event.stopPropagation();
                  handleChange(params.inputProps.value);
                }
              }}
              error={!isTransporterValid}
              helperText={!isTransporterValid && t('required')}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
            />
          )}
        />
      )}
      {pageStatus !== 'loaded' && <CircularProgress />}
    </>
  );
}
