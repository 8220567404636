import React, { useState } from 'react';
import {
  Select, MenuItem, InputLabel, FormControl, Box, Chip, ListItemText, Checkbox,
} from '@mui/material';
import useGlobal from 'global-state/store';

export default function ClientsSelect({
  updateCallback = () => {},
}) {
  const [globalState] = useGlobal();
  const organizations = Object.entries(globalState.clientOrganizations)
    .map((keyValue) => ({ id: keyValue[0], name: keyValue[1] }));
  const [activeOrganizations, setActiveOrganizations] = useState([]);

  const handleOrganizationChange = (event) => {
    const val = event.target.value;
    const newActiveOrganizations = typeof val === 'string' ? val.split(',') : val;
    setActiveOrganizations(newActiveOrganizations);
    updateCallback(newActiveOrganizations);
  };

  return (
    <FormControl sx={{ minWidth: 170 }}>
      <InputLabel id="client-select-label">Clients</InputLabel>
      <Select
        labelId="client-select-label"
        displayEmpty
        multiple
        size="large"
        label="clients"
        value={activeOrganizations}
        onChange={handleOrganizationChange}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} size="small" label={globalState.clientOrganizations[value]} />
            ))}
          </Box>
        )}
      >
        {organizations.map((org) => (
          <MenuItem key={org.id} value={org.id}>
            <Checkbox checked={activeOrganizations.indexOf(org.id) > -1} />
            <ListItemText primary={org.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
