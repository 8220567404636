export const setCookiesAccepted = (store, boolean) => {
  store.setState({ cookiesAccepted: boolean });
};

export const setSnackbarMessage = (store, options = { message: '', severiy: 'info' }) => {
  store.setState({
    globalSnackbar: {
      display: true,
      message: options.message,
      severity: options.severity,
    },
  });
};

export const closeSnackbar = (store) => {
  store.setState({ globalSnackbar: { display: false, message: '', severity: 'info' } });
};

export const setUserStatus = (store, status) => {
  store.setState({ userStatus: status });
};

export const setActiveOrganization = (store, organization) => {
  store.setState({ activeOrganization: organization });
};

export const setUserRoles = (store, anArray) => {
  store.setState({ roles: anArray });
};

export const setTransporterNames = (store, clientOrg, array) => {
  store.setState({
    transporterNamesSearched: true,
    transporterNames: { ...store.state.transporterNames, [clientOrg]: array },
  });
};

export const setClientOrganizations = (store, anObject) => {
  store.setState({ clientOrganizations: anObject });
};
