import React from 'react';
import './App.css';
import 'fontsource-roboto';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import { Container } from '@mui/material';
import {
  useFirebaseApp,
  AuthProvider,
  AnalyticsProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  FunctionsProvider,
  RemoteConfigProvider,
  useInitRemoteConfig,
  StorageProvider,
} from 'reactfire';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import CssBaseline from '@mui/material/CssBaseline';
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config';
import LoadingPage from 'components/LoadingPage';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import Home from 'components/Home';
import { initI18next } from './i18n/i18next';

const firebaseConfig = {
  apiKey: 'AIzaSyCa9rLf4xmacQg6xdoxOUxrWFrz7N_34W4',
  authDomain: 'gauger.labox-apps.com',
  projectId: 'labox-ws',
  storageBucket: 'labox-ws.appspot.com',
  messagingSenderId: '669483452439',
  appId: '1:669483452439:web:7e7dd4006b1c894c297452',
  measurementId: 'G-X8CGHETK4F',
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#437A89',
      contrastText: 'white',
    },
    secondary: {
      main: '#73a9b9',
      contrastText: 'white',
    },
    text: {
      primary: '#3f4d5a',
    },
    info: {
      main: '#437A89',
      contrastText: 'white',
    },
    ourGreen: {
      main: '#99BD5B',
      contrastText: 'white',
    },
    ourOrange: {
      main: '#f4ad45',
      contrastText: 'white',
    },
    gold: {
      main: '#FFD700',
      contrastText: 'white',
    },
    darkgray: {
      main: '#0000008a',
      contrastText: 'white',
    },
    mediumgray: {
      main: '#8585858a',
      contrastText: 'white',
    },
    gray: {
      main: '#d8d8d8',
      contrastText: 'white',
    },
  },
});

function AllReactFireProviders() {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const firestoreInstance = getFirestore(app);
  const functionsInstance = getFunctions(app);
  const storageInstance = getStorage(app);

  if (process.env.NODE_ENV === 'development') {
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFirestoreEmulator(firestoreInstance, 'localhost', 4401);
    connectFunctionsEmulator(functionsInstance, 'localhost', 5001);
    connectStorageEmulator(storageInstance, 'localhost', 9199);
  }

  return (
    <AnalyticsProvider sdk={getAnalytics(app)}>
      <AuthProvider sdk={auth}>
        <FirestoreProvider sdk={firestoreInstance}>
          <FunctionsProvider sdk={functionsInstance}>
            <StorageProvider sdk={storageInstance}>
              <ProvidersWithLoading />
            </StorageProvider>
          </FunctionsProvider>
        </FirestoreProvider>
      </AuthProvider>
    </AnalyticsProvider>
  );
}

function ProvidersWithLoading() {
  const { status: remoteConfigStatus, data: remoteConfigInstance } = useInitRemoteConfig(async (firebaseApp) => {
    const remoteConfig = getRemoteConfig(firebaseApp);
    remoteConfig.settings = {
      minimumFetchIntervalMillis: 600000,
      fetchTimeoutMillis: 600000,
    };

    await fetchAndActivate(remoteConfig);
    return remoteConfig;
  });

  if (remoteConfigStatus === 'loading') {
    return (
      <LoadingPage />
    );
  }

  return (
    <AllFirebaseProviders
      remoteConfigInstance={remoteConfigInstance}
    />
  );
}

function AllFirebaseProviders(props) {
  const { remoteConfigInstance } = props;

  return (
    <RemoteConfigProvider sdk={remoteConfigInstance}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container>
            <CssBaseline />
            <Home />
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    </RemoteConfigProvider>
  );
}

function App() {
  initI18next();
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <div className="App" style={{ whiteSpace: 'pre-line' }}>
        <AllReactFireProviders />
      </div>
    </FirebaseAppProvider>
  );
}

export default App;
