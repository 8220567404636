import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  collection,
  query,
  getDocs,
} from 'firebase/firestore';
import useGlobal from 'global-state/store';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useFirestore } from 'reactfire';

export default function CellarSelect({
  selectCallback, isCellarValid, defaultCellar = false, clientOrg,
}) {
  const { t } = useTranslation();
  const [globalState] = useGlobal();
  const [cellars, setCellars] = useState([]);
  const [pageStatus, setPageStatus] = useState('initial');
  const [currentCellar, setCurrentCellar] = useState('');
  const firestore = useFirestore();

  const handleChange = useCallback((newCellar) => {
    setCurrentCellar(newCellar);
    selectCallback(newCellar);
  }, [selectCallback]);

  const getCellars = useCallback(async () => {
    const cellarsQuery = query(collection(
      firestore,
      `organizations/${clientOrg}/apps/digitank-gauged-containers/cellars`,
    ));
    const querySnapshot = await getDocs(cellarsQuery);
    const cellarDocs = querySnapshot.docs;
    const newCellars = cellarDocs.map((doc) => doc.id);
    setCellars(newCellars);
    if (defaultCellar) {
      handleChange(newCellars[0]);
    }

    setPageStatus('loaded');
  }, [clientOrg, defaultCellar, firestore, handleChange]);

  useEffect(() => {
    if (pageStatus === 'initial' && globalState.activeOrganization !== '') {
      setPageStatus('loading');
      getCellars();
    }
  }, [getCellars, globalState.activeOrganization, pageStatus]);

  return (
    <>
      {pageStatus === 'loaded' && (
      <Autocomplete
        freeSolo
        size="small"
        sx={{ flexGrow: 1 }}
        options={cellars}
        value={currentCellar}
        onChange={(event, value) => handleChange(value)}
        renderInput={(params) => (
          <TextField
            label={t('tank_selection.cellar')}
            inputProps={{ maxLength: 30 }}
            onBlur={() => handleChange(params.inputProps.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                handleChange(params.inputProps.value);
              }
            }}
            error={!isCellarValid}
            helperText={!isCellarValid && t('required')}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
          />
        )}
      />
      )}
      {pageStatus !== 'loaded' && <CircularProgress />}
    </>
  );
}
