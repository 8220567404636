import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import FileDropzone from 'components/FileDropzone';
import DescriptionIcon from '@mui/icons-material/Description';
import Papa from 'papaparse';
import moment from 'moment';
import { replaceComma } from 'components/utils';
import useGlobal from 'global-state/store';
import modelTTInfoFr from 'staticFiles/modele-info-camion-citerne.csv';
import modelGCInfoFr from 'staticFiles/modele-info-contenant-jauge.csv';

export default function CertificateInfoDropZone(props) {
  const [, globalActions] = useGlobal();
  const {
    formik, errorHandler, aborthandler, type, acceptedHeightUnits, acceptedVolumeUnits, update = false,
  } = props;
  const { t } = useTranslation();

  function getExample() {
    if (type === 'tanker_truck') {
      return modelTTInfoFr;
    }
    return modelGCInfoFr;
  }

  const callback = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onerror = errorHandler;
    reader.onabort = aborthandler;
    reader.onload = (e) => {
      const parsedCsv = Papa.parse(reader.result, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
      });
      const errorMessage = t('certificate_creation.can_not_parse_csv');
      if (parsedCsv.errors.length > 0) {
        globalActions.setSnackbarMessage({
          message: `${errorMessage}\n${parsedCsv.errors[0].message}`,
          severity: 'error',
        });
      } else {
        try {
          setFormikValues(modelFromData(parsedCsv.data[0]));
        } catch (error) {
          globalActions.setSnackbarMessage({ message: errorMessage, severity: 'error' });
        }
        formik.setFieldValue('infoFileName', file.path);
        setTimeout(() => formik.validateForm(), 0);
      }
    };

    reader.readAsText(file);
  };

  function modelFromData(data) {
    const defaultModel = {
      ownerOrganizationName: data.proprietaire,
      certificateName: data.id_certificat,
      tankName: data.id_recipient,
      cellarName: data.id_chai,
      heightUnit: data.unites_de_hauteur,
      volumeUnit: data.unites_de_volume,
      capacity: replaceComma(data.capacite_citerne),
      expirationDate: moment(
        data.date_limite_de_validite,
        'DD/MM/YYYY',
      ),
      gaugeDate: moment(data.date_de_la_verification, 'DD/MM/YYYY'),
      gaugingProviderName: data.prestataire_jaugeage_pdf || '',
    };
    let extras;
    if (type === 'tanker_truck') {
      extras = {
        nominalVolumesFromInfo: nominalVolumesFromFileInfo(data),
      };
    } else {
      extras = {
        nominalVolumes: replaceComma(data.volume_nominal),
      };
    }
    return { ...defaultModel, ...extras };
  }

  function setFormikValues(model) {
    if (update) {
      if (model.ownerOrganizationName !== formik.values.ownerOrganizationName) {
        globalActions.setSnackbarMessage({
          message: t('certificate_creation.client_not_found'),
          severity: 'error',
        });
      } else {
        formik.setFieldValue('ownerOrganizationName', model.ownerOrganizationName);
      }
      if (model.certificateName !== formik.values.certificateName) {
        globalActions.setSnackbarMessage({
          message: t('certificate_creation.not_same_certificate_id'),
          severity: 'error',
        });
      }
      if (model.tankName !== formik.values.tankName) {
        globalActions.setSnackbarMessage({
          message: t('certificate_creation.not_same_tank_id'),
          severity: 'error',
        });
      }
    } else {
      formik.setFieldValue('ownerOrganizationName', model.ownerOrganizationName);
      formik.setFieldValue('tankName', model.tankName);
      formik.setFieldValue('cellarName', model.cellarName);
      formik.setFieldValue('gaugingProviderName', model.gaugingProviderName);
      formik.setFieldValue('certificateName', model.certificateName);
    }
    formik.setFieldValue('heightUnit', unitOrEmptyString(acceptedHeightUnits, model.heightUnit));
    formik.setFieldValue('volumeUnit', unitOrEmptyString(acceptedVolumeUnits, model.volumeUnit));
    formik.setFieldValue('capacity', model.capacity);
    formik.setFieldValue('expirationDate', model.expirationDate);
    formik.setFieldValue('gaugeDate', model.gaugeDate);
    if (type === 'tanker_truck') {
      formik.setFieldValue(
        'nominalVolumesFromInfo',
        model.nominalVolumesFromInfo,
      );
    } else {
      formik.setFieldValue(
        'nominalVolumes',
        model.nominalVolumes,
      );
    }
  }

  function unitOrEmptyString(availableUnits, newUnit) {
    if (!availableUnits.includes(newUnit)) {
      return '';
    }
    return newUnit;
  }

  function nominalVolumesFromFileInfo(data) {
    const compartmentVolumeHeaders = Object.keys(data).filter(
      (header) => header.includes('Volume nominal: ') || header.includes('volume_nominal_'),
    );
    const compartmentNamesAndValues = compartmentVolumeHeaders.reduce(
      (all, header) => ({
        ...all,
        [header.split(/Volume nominal: |volume_nominal_/)[1]]: data[header],
      }),
      {},
    );
    return compartmentNamesAndValues;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <FileDropzone
        text={t('certificate_creation.upload_csv_text')}
        callback={callback}
        accept={{ 'text/csv': ['.csv'] }}
        fileName={formik.values.infoFileName}
      />
      <a href={getExample()} style={{ textDecoration: 'none' }} download>
        <Button
          variant="outlined"
          component="span"
          startIcon={<DescriptionIcon />}
        >
          {t('menu_boxettes.download_model_CSV')}
        </Button>
      </a>
    </Box>
  );
}
