import * as yup from 'yup';

export default function validationSchema(t) {
  // https://firebase.google.com/docs/firestore/quotas#collections_documents_and_fields
  const validFirestoreIdExp = /^(?!\.\.?$)(?!.*__.*__)([^/]{1,1500})$/;
  const firestoreIdExpErrorMessage = `${t('certificate_creation.invalid_utf8')}\n${
    t('certificate_creation.less_than_1500')}\n${
    t('certificate_creation.no_slash')}\n${
    t('certificate_creation.not_a_dot')}\n${
    t('certificate_creation.not__.*__')}`;

  return yup.object().shape({
    certificateId: yup.string()
      .matches(validFirestoreIdExp, firestoreIdExpErrorMessage)
      .required(t('validations.required')),
    certificateName: yup.string()
      .required(t('validations.required')),
    type: yup.string()
      .required(t('validations.required')),
    ownerOrganizationName: yup.string()
      .nullable()
      .required(t('validations.required')),
    gaugerOrganizationId: yup.string()
      .matches(validFirestoreIdExp, firestoreIdExpErrorMessage)
      .required(t('validations.required')),
    gaugerOrganizationName: yup.string()
      .required(t('validations.required')),
    tankId: yup.string()
      .matches(validFirestoreIdExp, firestoreIdExpErrorMessage)
      .required(t('validations.required')),
    tankName: yup.string()
      .required(t('validations.required')),
    cellarName: yup.string()
      .required(t('validations.required')),
    expirationDate: yup.date().required(t('validations.required')),
    gaugeDate: yup.date().required(t('validations.required')),
    heightUnit: yup.string()
      .oneOf(['m'], t('certificate_creation.invalid_height_unit'))
      .required(t('certificate_creation.invalid_height_unit')),
    volumeUnit: yup.string()
      .oneOf(['m3'], t('certificate_creation.invalid_volume_unit'))
      .required(t('certificate_creation.invalid_volume_unit')),
    capacity: yup
      .number()
      .required(t('validations.required'))
      .min(0, t('validations.more_than_0')),
    compartmentsData: yup.string().required(),
  });
}
