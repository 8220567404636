/* eslint-disable react/jsx-props-no-spreading */
// TODO unspread props
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';

export default function FileDropzone(props) {
  const { t } = useTranslation();
  const {
    callback, text, accept, disabled, fileName,
  } = props;
  const maxSize = 2 * 1024 * 1024;
  const maxFiles = 1;
  // const [fileName, setfileName] = React.useState('...');
  const [, globalActions] = useGlobal();

  const onDrop = React.useCallback((acceptedFiles, rejectedFiles) => {
    rejectedFiles.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-invalid-type') {
          globalActions.setSnackbarMessage({ message: t('certificate_creation.file_invalid_type'), severity: 'error' });
        }
        if (err.code === 'file-too-large') {
          globalActions.setSnackbarMessage({ message: t('certificate_creation.file_too_big'), severity: 'error' });
        }
      });
    });
    if (acceptedFiles.length > 0) {
      callback(acceptedFiles);
    }
  }, [callback, globalActions, t]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    maxSize,
    maxFiles,
    disabled,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />

      <Box
        {...getRootProps()}
        sx={{
          color: disabled ? '#808080' : '#437A89',
          border: 1,
          p: 5,
          borderRadius: 5,
          borderStyle: 'dashed',
        }}
      >
        <Typography
          sx={{ color: disabled ? '#808080' : '#437A89' }}
          variant="h6"
          gutterBottom
          component="div"
        >
          {text}
        </Typography>
        <div style={{ height: 10 }} />

        <Button variant="contained" color="info" disabled={disabled}>
          <InsertDriveFile />
          {fileName}
        </Button>
      </Box>
    </div>
  );
}
