import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export default function CertificateListHeaders() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        flexWrap: 'wrap',
        rowGap: 1,
      }}
    >
      <Typography sx={{ flexGrow: 2, flexBasis: { xs: '50%', sm: 2 } }}>
        {t('admin.tank_id')}
      </Typography>
      <Typography sx={{ flexGrow: 1.5, flexBasis: { xs: '50%', sm: 2 } }}>
        Type
      </Typography>
      <Typography sx={{ flexGrow: 2, flexBasis: { xs: '100%', sm: 2 } }}>
        {t('owner')}
      </Typography>
      <Typography sx={{ flexGrow: 2.5, flexBasis: { xs: '100%', sm: 2.5 } }}>
        {t('expiration_date')}
      </Typography>
      <Typography sx={{ flexGrow: 2.5, flexBasis: { xs: '100%', sm: 2.5 } }}>
        {t('admin.last_modified')}
      </Typography>
    </Box>
  );
}
