import React, { useState } from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mui/material';

export default function CertificateListItem({
  index, handleOpen, certificate, alternateBackground, selectCallback,
}) {
  const { t } = useTranslation();
  const background = alternateBackground ? 'white' : '#edeff2';
  const [checked, setChecked] = useState(false);

  const statusColor = (status) => {
    switch (status) {
      case 'rejected':
        return 'red';
      case 'inUse':
        return 'green';
      default:
        return 'primary.main';
    }
  };

  const handleChecked = (event) => {
    setChecked(event.target.checked);
    selectCallback(event.target.checked, certificate);
  };

  return (
    <ListItem
      key={index}
      component={Box}
      sx={{ background, m: 0, p: 0 }}
    >
      <ListItemButton sx={{ p: 2 }} onClick={() => handleOpen(certificate)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            flexWrap: 'wrap',
            rowGap: 1,
          }}
        >
          <Checkbox checked={checked} onChange={handleChecked} onClick={(e) => e.stopPropagation()} />
          <Typography sx={{ flexGrow: 2, flexBasis: { xs: '50%', sm: 2 } }}>
            {certificate.certificateName}
          </Typography>
          <Typography sx={{ flexGrow: 2, flexBasis: { xs: '50%', sm: 2 } }}>
            {t(`type.${certificate.type}`)}
          </Typography>
          <Typography sx={{ flexGrow: 2, flexBasis: { xs: '100%', sm: 2 } }}>
            {certificate.ownerOrganizationName}
          </Typography>
          <Typography sx={{
            flexGrow: 2.5,
            flexBasis: { xs: '100%', sm: 2.5 },
            textAlign: { xs: 'start', sm: 'center' },
          }}
          >
            {new Date(certificate.expirationDate.toMillis()).toLocaleString()}
          </Typography>
          <Typography sx={{ flexGrow: 2.5, flexBasis: { xs: '100%', sm: 2.5 }, textAlign: { xs: 'start', sm: 'end' } }}>
            {new Date(certificate.lastModified.toMillis()).toLocaleString()}
          </Typography>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
