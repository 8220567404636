import {
  doc, query, collection, getDocs, getDoc,
} from 'firebase/firestore';

async function getTankIds(firestore, transp) {
  const tanksQuery = query(collection(firestore, `${transp.ref.path}/tanks`));
  const querySnapshot = await getDocs(tanksQuery);
  const tanksIdsPromises = querySnapshot.docs.map(async (tankDoc) => {
    const tankDataRef = doc(firestore, `organizations/${transp.id}/apps/digitank-transporter/tanks/${tankDoc.id}`);
    const tankData = await getDoc(tankDataRef);
    return {
      id: tankDoc.id,
      path: tankData.ref.path,
      transporterName: tankData.data().transporterName,
    };
  });
  const tanksIds = await Promise.all(tanksIdsPromises);
  return tanksIds;
}

function groupTanksByTransporterIdAndName(transportersAndTanks) {
  const groupedTanks = {};

  Object.keys(transportersAndTanks).forEach((transporterId) => {
    const transporterTanks = transportersAndTanks[transporterId].tanks;
    Object.keys(transporterTanks).forEach((tankId) => {
      const tank = transporterTanks[tankId];
      const key = `${transportersAndTanks[transporterId].orgName}::${tank.transporterName}`;
      if (!groupedTanks[key]) {
        groupedTanks[key] = [];
      }
      groupedTanks[key].push(tank);
    });
  });
  return groupedTanks;
}

export default async function getTransporterIdsAndTankIds(
  db,
  clientOrg,
) {
  const transportersQuery = query(
    collection(db, `organizations/${clientOrg}/apps/digitank-tanker-trucks`, 'transporters'),
  );
  const querySnapshot = await getDocs(transportersQuery);
  const transporterDocs = querySnapshot.docs;

  let newTransportersAndTanks = {};

  const transporterTanksPromises = transporterDocs.map(async (transp) => {
    const transporterTanks = await getTankIds(transp.ref.firestore, transp);
    const orgRef = doc(db, `organizations/${transp.id}`);
    const orgDoc = await getDoc(orgRef);
    newTransportersAndTanks[transp.id] = { tanks: transporterTanks, orgName: orgDoc.data().name };
  });

  await Promise.all(transporterTanksPromises);
  newTransportersAndTanks = groupTanksByTransporterIdAndName(newTransportersAndTanks);
  return newTransportersAndTanks;
}
