import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import { Box, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/FindInPage';
import TypeSelect from './TypeSelect';
import OwnerNamesSelect from '../OwnerNamesSelect';
import OrderBySelect from './OrderBySelect';

export default function CertificateFilters({
  formik, datePickersLabel = '',
  computeListOfConditions,
}) {
  const { t } = useTranslation();

  // const handleStartDateChange = (newValue) => {
  //   const newDate = newValue;
  //   if (!newDate.isSame(formik.values.lastModifiedStartDate)) {
  //     if (newDate.isAfter(formik.values.lastModifiedEndDate)) {
  //       const newEndDate = newDate.clone().endOf('day');
  //       formik.setFieldValue('lastModifiedEndDate', newEndDate);
  //     }
  //     formik.setFieldValue('lastModifiedStartDate', newDate);
  //   }
  // };

  // const handleEndDateChange = (newValue) => {
  //   const newDate = newValue;
  //   if (!newDate.isSame(formik.values.lastModifiedEndDate)) {
  //     if (newDate.isBefore(formik.values.lastModifiedStartDate)) {
  //       const newStartDate = newDate.clone().startOf('day');
  //       formik.setFieldValue('lastModifiedStartDate', newStartDate);
  //     }
  //     formik.setFieldValue('lastModifiedEndDate', newDate);
  //   }
  // };

  return (
    <Paper
      elevation={0}
      sx={{
        px: 1,
        py: 2,
        width: '100%',
        display: 'flex',
        gap: 2,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <Box sx={{
        width: '100%',
        display: 'flex',
        gap: 1,
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
      >
        <OwnerNamesSelect
          updateCallback={(newOrgNames) => {
            formik.setFieldValue('ownerOrganizationNames', newOrgNames);
          }}
        />
        <TypeSelect
          updateCallback={(newTypeId, newTypeName) => {
            formik.setFieldValue('type', newTypeId);
          }}
        />
        <OrderBySelect updateCallback={(newOrderBy) => {
          formik.setFieldValue('orderBy', newOrderBy);
        }}
        />
        <Button
          disabled={!formik.isValid}
          type="submit"
          size="small"
          variant="contained"
          color="primary"
          endIcon={<SearchIcon />}
        >
          {t('apply_filters')}
        </Button>
      </Box>
      {/* <Box sx={{
        width: '100%',
        display: 'flex',
        gap: 2,
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
      >

        <Typography variant="body2">
          {datePickersLabel}
          {' '}
          :
        </Typography>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
          <DatePicker
            label={t('admin.between_start_date')}
            format="DD/MM/YYYY"
            value={formik.values.lastModifiedStartDate}
            onChange={handleStartDateChange}
            slotProps={{
              textField: {
                error: formik.errors.lastModifiedStartDate && formik.touched.lastModifiedStartDate,
                onBlur: () => {
                  formik.setFieldTouched('lastModifiedStartDate', true);
                },
              },
            }}
          />
          <DatePicker
            label={t('admin.between_end_date')}
            value={formik.values.lastModifiedEndDate}
            format="DD/MM/YYYY"
            onChange={handleEndDateChange}
            slotProps={{
              textField: {
                error: formik.errors.lastModifiedEndDate && formik.touched.lastModifiedEndDate,
                onBlur: () => {
                  formik.setFieldTouched('lastModifiedEndDate', true);
                },
              },
            }}
          />
        </LocalizationProvider>
      </Box> */}
    </Paper>
  );
}
