import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { useNavigate } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  where,
} from 'firebase/firestore';
import { useUser } from 'reactfire';
import CircularProgress from '@mui/material/CircularProgress';
import useGlobal from 'global-state/store';
import { useFormik } from 'formik';
import OurPagination from 'components/OurPagination';
import { Button } from '@mui/material';
import CertificateDetail from './CertificateDetail';
import CertificateListHeaders from './CertificateListHeaders';
import CertificateListItem from './CertificateListItem';
import CertificateFilters from './CertificateFilters';
import SelectedCertificatesActions from './SelectedCertificatesActions';

export default function Certificates() {
  const { t } = useTranslation();
  const analytics = getAnalytics();
  const { data: user } = useUser();
  const [globalState] = useGlobal();
  const navigate = useNavigate();
  const [orderBy, setOrderBy] = useState('lastModified');

  const computeListOfConditions = useCallback((formikValues) => {
    const conditions = [
      // where('lastModified', '<=', formikValues.lastModifiedEndDate.toDate()),
      // where('lastModified', '>=', formikValues.lastModifiedStartDate.toDate()),
    ];

    const addInCondition = (key, value, all) => {
      if (value && value !== '' && value !== '-') {
        conditions.push(where(key, 'in', [value]));
      } else {
        conditions.push(where(key, 'in', all));
      }
    };

    if (formikValues.ownerOrganizationNames.length > 0) {
      conditions.push(where('ownerOrganizationName', 'in', formikValues.ownerOrganizationNames));
    }

    addInCondition('type', formikValues.type, ['tanker_truck', 'gauged_container']);

    return conditions;
  }, []);

  const formikInitValues = useMemo(() => ({
    ownerOrganizationNames: [],
    // lastModifiedStartDate: initialStartDate(),
    // lastModifiedEndDate: initialEndDate(),
    type: '',
    orderBy: 'lastModified',
  }), []);

  const [listOfConditions, setListOfConditions] = useState(computeListOfConditions(formikInitValues));

  // function initialStartDate() {
  //   return moment().subtract(10, 'years');
  // }

  // function initialEndDate() {
  //   return moment().endOf('day');
  // }

  useEffect(() => {
    if (globalState.activeOrganization !== '' && user?.uid) {
      logEvent(analytics, 'loading_certificates', {
        user_uid: user?.uid,
        appName: 'Digitank, Gauger',
        organization: globalState.activeOrganization,
      });
    }
  }, [analytics, globalState.activeOrganization, user?.uid]);

  const handleCreate = (type) => {
    navigate('create', {
      state: {
        type,
      },
    });
  };

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 2, mb: { xs: 8, md: 1 },
      }}
    >
      <Typography variant="h5" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('admin.certificates')}
      </Typography>
      {globalState.activeOrganization && (
      <OurPagination
        collectionPath={`organizations/${globalState.activeOrganization}/apps/digitank-gauger/gaugerCertificates`}
        listOfConditions={listOfConditions}
        orderByAttribute={orderBy}
        filters={(
          <CertificateListFilters
            formikInitValues={formikInitValues}
            setListOfConditions={setListOfConditions}
            computeListOfConditions={computeListOfConditions}
            setOrderBy={setOrderBy}
          />
            )}
        list={(
          <CertificateList listOfConditions={listOfConditions} />
            )}
      />
      )}
      {!globalState.activeOrganization && <CircularProgress />}

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: 1,
        position: 'fixed',
        bottom: '5%',
        right: '5%',
      }}
      >
        <Fab
          color="primary"
          aria-label="create"
          component={Button}
          onClick={() => handleCreate('tanker_truck')}
          variant="extended"
          disabled
        >
          <AddIcon />
          {t('certificate_creation.add_tanker_truck_button')}
        </Fab>
        <Fab
          color="primary"
          aria-label="create"
          component={Button}
          onClick={() => handleCreate('gauged_container')}
          variant="extended"
        >
          <AddIcon />
          {t('certificate_creation.add_gauged_container_button')}
        </Fab>
      </Box>
    </Box>
  );
}

function CertificateList({
  docs, initSearch, listOfConditions,
}) {
  const [activeCertificate, setActiveCertificate] = useState('');
  const [open, setOpen] = useState(false);
  const certificates = docs.map((d) => d.data());
  const [selectedCertificates, setSelectedCertificates] = useState([]);

  const handleOpen = (certificate) => {
    setActiveCertificate(certificate);
    setOpen(true);
  };

  const handleSelectCertificate = (selected, certificate) => {
    if (selected) {
      setSelectedCertificates((prevCertificates) => [...prevCertificates, certificate]);
    } else {
      setSelectedCertificates((prevCertificates) => prevCertificates.filter(
        (cert) => cert.certificateId !== certificate.certificateId,
      ));
    }
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%', gap: 1,
    }}
    >
      <CertificateListHeaders />
      <List
        dense
        sx={{
          width: '100%', border: 2, p: 0, borderRadius: 1, borderColor: 'white',
        }}
      >
        {certificates.map((certificate, i) => (
          <div key={certificate.certificateId}>
            <CertificateListItem
              index={i}
              alternateBackground={i % 2 === 0}
              certificate={certificate}
              handleOpen={handleOpen}
              selectCallback={handleSelectCertificate}
            />
          </div>
        ))}
      </List>
      <Box sx={{ alignSelf: 'flex-start' }}>
        <SelectedCertificatesActions selectedCertificates={selectedCertificates} />
      </Box>
      <CertificateDetail
        open={open}
        setOpen={setOpen}
        certificate={activeCertificate}
        certificateDeleteCallback={() => initSearch(listOfConditions)}
      />
    </Box>
  );
}

function CertificateListFilters({
  initSearch, formikInitValues, setListOfConditions, computeListOfConditions, setOrderBy,
}) {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: formikInitValues,
    onSubmit: (values) => {
      const newConditions = computeListOfConditions(values);
      setListOfConditions(newConditions);
      setOrderBy(values.orderBy);
      initSearch(newConditions, values.orderBy);
    },
  });

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%',
    }}
    >
      <CertificateFilters formik={formik} datePickersLabel={t('admin.last_modified')} />
    </Box>
  );
}
