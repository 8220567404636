import React, { useState } from 'react';
import {
  Select, MenuItem, InputLabel, FormControl,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function TypeSelect({ updateCallback = () => {} }) {
  const { t } = useTranslation();
  const typeKeys = ['-', 'tanker_truck', 'gauged_container'];
  const allTypes = typeKeys.reduce((acc, key) => {
    acc[key] = t(`type.${key}`);
    return acc;
  }, {});
  allTypes['-'] = t('all');

  const types = Object.keys(allTypes);
  const typeNames = allTypes;
  const [activeType, setActiveType] = useState(types[0]);

  const handleTypeChange = async (event) => {
    const newActiveType = event.target.value;
    setActiveType(newActiveType);
    updateCallback(newActiveType, typeNames[newActiveType]);
  };

  return (
    <SelectCore
      activeType={activeType}
      handleTypeChange={handleTypeChange}
      types={types}
      typeNames={typeNames}
    />
  );
}

function SelectCore({
  activeType, handleTypeChange, types, typeNames,
}) {
  return (
    <FormControl sx={{ minWidth: 170 }}>
      <InputLabel id="client-select-label">Type</InputLabel>
      <Select
        labelId="client-select-label"
        displayEmpty
        size="large"
        label="Type"
        value={activeType}
        onChange={handleTypeChange}
      >
        {types.map((organizationId) => (
          <MenuItem key={organizationId} value={organizationId}>
            {typeNames[organizationId]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>

  );
}
