import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
// import Truck from '@mui/icons-material/LocalShipping';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import ArticleIcon from '@mui/icons-material/Article';
import OrganizationsSelect from 'components/OrganizationsSelect';
import NotYetSetup from 'components/NotYetSetup';
import useGlobal from 'global-state/store';
// import GaugedContainerIcon from 'icons/GaugedContainerIcon';
import CertificatesMonitoring from './CertificatesMonitoring';
import Notifications from './notifications/Notifications';

export default function Admin() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
        mb: 15,
        mt: { xs: 5, md: 10 },
      }}
    >
      <OrganizationsSelect />
      <AdminButtons />
    </Box>
  );
}

function AdminButtons() {
  const { t } = useTranslation();

  const [globalState] = useGlobal();
  if (globalState.userStatus === 'notYetSetup') {
    return (
      <NotYetSetup />
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: 3,
        }}
      >
        <MenuButton
          to="certificates"
          icon={<ArticleIcon sx={{ fontSize: 150 }} />}
          text={t('admin.certificates')}
        />
        <MenuButton
          component="a"
          target="_blank"
          href="https://orgs-ws.labox-apps.com"
          icon={<PeopleAlt sx={{ fontSize: 150 }} />}
          text={t('admin.users_management')}
        />
      </Box>
      {/* <Typography
        variant="h6"
        component="div"
        sx={{
          textAlign: 'left', alignSelf: 'flex-start',
        }}
      >
        Clients
        :
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: 3,
        }}
      >
        <MenuButton
          to="truck-tanks"
          icon={<Truck sx={{ fontSize: 150 }} />}
          text={t('admin.client_truck_tanks')}
        />
        <MenuButton
          to="cellar-tanks"
          icon={<GaugedContainerIcon size={150} color="#FFFFFF" />}
          text={t('admin.client_cellar_tanks')}
        />
      </Box> */}

      <Typography
        variant="h6"
        component="div"
        sx={{
          textAlign: 'left', alignSelf: 'flex-start',
        }}
      >
        {t('admin.monitoring')}
        :
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'row'],
          alignItems: 'stretch',
          height: '100%',
          gap: 3,
          width: { xs: '100%', md: 800 },
        }}
      >
        <Box
          sx={{
            flexBasis: '50%',
            flexGrow: 1,
            width: ['100%', '50%'],
            height: '100%',
          }}
        >
          <Notifications />
        </Box>
        <Box
          sx={{
            flexBasis: '50%',
            flexGrow: 1,
            width: ['100%', '50%'],
            height: '100%',
          }}
        >
          <CertificatesMonitoring />
        </Box>
      </Box>

    </Box>
  );
}

function MenuButton(props) {
  const {
    disabled, to, icon, text, component, href, target,
  } = props;

  return (
    <Button
      disabled={disabled}
      color="info"
      sx={{
        p: 2, gap: 2, display: 'flex', flexDirection: 'column', width: { xs: '100%', sm: 300 },
      }}
      variant="contained"
      component={component || Link}
      href={href}
      target={target}
      to={to}
    >
      {icon}
      {text}
    </Button>
  );
}
