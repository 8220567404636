import React, { useEffect } from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { collection } from 'firebase/firestore';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';

export default function SharedCertificateStatus({ certificate, statusesCallback = () => {} }) {
  const firestore = useFirestore();
  const { t } = useTranslation();
  const [globalState] = useGlobal();

  const permissionsCollectionRef = collection(
    firestore,
    `organizations/${certificate.gaugerOrganizationId}/apps/digitank-gauger/`
    + `gaugerCertificatePermissions/${certificate.certificateId}/accessibility`,
  );

  const { status, data: sharedStatuses } = useFirestoreCollectionData(permissionsCollectionRef);

  useEffect(() => {
    if (status === 'success') {
      statusesCallback(sharedStatuses);
    }
  }, [status, sharedStatuses, statusesCallback]);

  const statusColor = (shareStatus) => {
    switch (shareStatus) {
      case 'rejected':
        return 'red';
      case 'inUse':
        return 'green';
      default:
        return '#437A89';
    }
  };

  if (status !== 'success') {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="body2">
        Status
      </Typography>
      {sharedStatuses.length === 0 && (
      <Typography variant="body2" sx={{ color: '#8585858a' }}>
        {t('selected_certificates.no_usage')}
      </Typography>
      )}
      {sharedStatuses.map((statusData) => (
        <Box key={statusData.NO_ID_FIELD} sx={{ display: 'flex' }}>
          <Typography variant="body2">
            {globalState.clientOrganizations[statusData.NO_ID_FIELD]}
            {' : '}
          </Typography>
          <Typography variant="body2" sx={{ color: statusColor(statusData.status), ml: 1 }}>
            {`${t(`status.${statusData.status}`)}`}
          </Typography>
        </Box>

      ))}
    </Box>
  );
}
