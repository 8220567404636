import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import { useFunctions } from 'reactfire';
import useGlobal from 'global-state/store';
import { httpsCallable } from 'firebase/functions';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import { Box, Typography } from '@mui/material';
import ClientsSelect from '../ClientsSelect';
import SharedCertificateStatus from './SharedCertificateStatus';

export default function SendCertificatesToClients({
  open, handleClose, selectedCertificates, successCallback,
}) {
  const [loading, setLoading] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);
  const [, globalActions] = useGlobal();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const notifyClientNewCertificates = httpsCallable(functions, 'notifyClientNewCertificatesCall');
  const { t } = useTranslation();
  const allStatuses = selectedCertificates.map(
    (certificate) => ({ certificateId: certificate.certificateId, statuses: {} }),
  );

  const updateStatusesForCertificate = (certificateId, updatedStatuses) => {
    const certificate = allStatuses.find((cert) => cert.certificateId === certificateId);
    certificate.statuses = updatedStatuses;
  };

  const handleSendCertificates = async () => {
    if (!selectedClients.length) {
      globalActions.setSnackbarMessage({
        message: t('selected_certificates.select_at_least_one_client'),
        severity: 'warning',
      });
      return;
    }
    const alreadySentCertificates = [];
    for (const certificateStatuses of allStatuses) {
      for (const clientId of selectedClients) {
        if (['notified'].includes(certificateStatuses.statuses.find(
          (status) => status.NO_ID_FIELD === clientId,
        ))) {
          alreadySentCertificates.push(certificateStatuses);
        }
      }
    }

    if (alreadySentCertificates.length > 0) {
      globalActions.setSnackbarMessage({
        message: t(
          'selected_certificates.already_sent_to_clients',
          { certificates: alreadySentCertificates.map((certificate) => certificate.certificateId).join(', ') },
        ),
        severity: 'error',
      });
      return;
    }
    setLoading(true);
    try {
      const firstCertif = selectedCertificates[0];

      const promises = selectedClients.map(async (clientId) => notifyClientNewCertificates({
        notifiedOrganizationId: clientId,
        tanksAndCertificates: selectedCertificates,
        gaugerOrganizationId: firstCertif.gaugerOrganizationId,
        gaugerOrganizationName: firstCertif.gaugerOrganizationName,
        certificatesType: firstCertif.type,
        clientId,
      }));

      await Promise.all(promises);
      successCallback();
    } catch (e) {
      console.error(e);
      globalActions.setSnackbarMessage({ message: e.message, severity: 'error' });
    }
    setLoading(false);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 2,
      }}
      >
        <Typography>
          {t('selected_certificates.certificate_usages')}
          {' : '}
        </Typography>
        <Box sx={{
          display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1,
        }}
        >
          {selectedCertificates.map((certificate) => (
            <Box
              key={certificate.certificateName}
              sx={{
                display: 'flex', flexDirection: 'column', p: 1, border: 1, borderRadius: 1,
              }}
            >
              <Typography variant="body2">
                {certificate.certificateName}
              </Typography>
              <Typography variant="caption" sx={{ mb: 1 }}>
                {certificate.certificateNane}
              </Typography>

              <SharedCertificateStatus
                certificate={certificate}
                statusesCallback={(statuses) => {
                  updateStatusesForCertificate(certificate.certificateId, statuses);
                }}
              />
            </Box>
          ))}
        </Box>
        <Typography>
          {t('send_to')}
          {' : '}
        </Typography>
        <ClientsSelect
          updateCallback={(orgIdAndNames) => {
            setSelectedClients(orgIdAndNames);
          }}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          disabled={!selectedCertificates.length}
          loading={loading}
          color="success"
          variant="contained"
          onClick={handleSendCertificates}
          endIcon={<SendIcon />}
        >
          {t('send')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
