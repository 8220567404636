import React from 'react';
import {
  Autocomplete, CircularProgress, TextField,
} from '@mui/material';
import useGlobal from 'global-state/store';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { doc } from 'firebase/firestore';

export default function ClientAutocomplete({
  inputValue,
  updateCallback = () => {},
  error,
  helperText,
}) {
  const [globalState] = useGlobal();
  const firestore = useFirestore();

  const actualInputValue = inputValue || '';

  const handleOrganizationChange = async (newOrg) => {
    updateCallback(newOrg);
  };

  const organizationAppRef = doc(
    firestore,
    `organizations/${globalState.activeOrganization}/apps/digitank-gauger`,
  );

  const {
    status: organizationAppStatus,
    data: organizationAppData,
  } = useFirestoreDocData(organizationAppRef);

  if (organizationAppStatus !== 'success') {
    return <CircularProgress />;
  }

  return (
    <Autocomplete
      freeSolo
      sx={{ width: 195 }}
      options={organizationAppData.certificateOwnerNames || []}
      value={actualInputValue}
      onChange={(event, value) => handleOrganizationChange(value)}
      renderInput={(params) => (
        <TextField
          label="Client"
          onBlur={() => handleOrganizationChange(params.inputProps.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              event.stopPropagation();
              handleOrganizationChange(params.inputProps.value);
            }
          }}
          error={error}
          helperText={helperText}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
        />
      )}
    />
  );
}
