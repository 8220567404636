import React from 'react';
import { useFirestore, useFirestoreDocDataOnce } from 'reactfire';
import {
  doc,
} from 'firebase/firestore';
import useGlobal from 'global-state/store';
import { Box, CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import CertificateCreationForm from './CertificateCreationForm';

export default function CertificateCreation() {
  const [globalState] = useGlobal();
  const location = useLocation();
  const { type, certificate: existingCertificate } = location.state;
  const update = location.state.update || false;

  if (globalState.activeOrganization !== '') {
    return (
      <CertificateCreationContent
        type={type}
        update={update}
        activeOrg={globalState.activeOrganization}
        existingCertificate={existingCertificate}
      />
    );
  }

  return (
    <Box
      sx={{
        height: '75vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}

function CertificateCreationContent({
  type, update = false, activeOrg, existingCertificate,
}) {
  const firestore = useFirestore();

  const appDocRef = doc(
    firestore,
    `organizations/${activeOrg}`,
  );

  const { status: activeOrgStatus, data: activeOrgData } = useFirestoreDocDataOnce(appDocRef);

  if (activeOrgStatus === 'success') {
    return (
      <CertificateCreationForm
        type={type}
        update={update}
        gaugerOrganizationName={activeOrgData.name}
        gaugerOrganizationId={activeOrg}
        existingCertificate={existingCertificate}
      />
    );
  }

  return (
    <Box
      sx={{
        height: '75vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
