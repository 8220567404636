import * as React from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { NumericFormat } from 'react-number-format';
import { replaceComma } from 'components/utils';

export default function NominalVolumes(props) {
  const { t } = useTranslation();
  const { formik } = props;

  React.useEffect(() => {
    const newList = {};
    for (const name of formik.values.nominalVolumesNamesFromData) {
      newList[name] = replaceComma(formik.values.nominalVolumesFromInfo[name]);
    }
    formik.setFieldValue('nominalVolumes', newList);
  // This is disabled due to https://github.com/jaredpalmer/formik/issues/1677
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.nominalVolumesFromInfo,
    formik.values.nominalVolumesNamesFromData,
    formik.setFieldValue,
  ]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="h6" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('certificate_creation.nominal_volumes')}
      </Typography>
      <Paper
        elevation={0}
        sx={{
          p: 1,
          display: 'flex',
          flexDirection: 'row',
          gap: 3,
          flexWrap: 'wrap',
        }}
      >
        {Object.entries(formik.values.nominalVolumes).map((nominalValue, i) => (
          <div key={nominalValue[0]}>
            <NominalVolume
              index={i}
              nominalValue={nominalValue}
              formik={formik}
            />
          </div>
        ))}
      </Paper>
    </Box>
  );
}

function NominalVolume(props) {
  const { index, nominalValue, formik } = props;
  const { t } = useTranslation();

  function handleVolumeChange(newValue) {
    formik.values.nominalVolumes[nominalValue[0]] = newValue;
    formik.handleChange({
      target: {
        name: 'nominalVolumes',
        value: formik.values.nominalVolumes,
      },
    });
  }

  return (
    <ListItem
      key={index}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        gap: 1,
      }}
    >
      <Typography component="span" variant="subtitle1" gutterBottom>
        {nominalValue[0]}
        :
      </Typography>
      <NumericFormat
        sx={{ maxWidth: 150 }}
        id={`nominalVolumes-${index}`}
        name={`nominalVolumes-${index}`}
        label={t('certificate_creation.compartment_value')}
        className="TextFieldBackground"
        variant="outlined"
        autoComplete="off"
        valueIsNumericString
        allowNegative={false}
        value={nominalValue[1]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        allowedDecimalSeparators={['.', ',']}
        decimalSeparator="."
        type="text"
        inputProps={{ inputMode: 'decimal' }}
        customInput={TextField}
        helperText={
          formik.errors.nominalVolumes
          && formik.touched.nominalVolumes
          && formik.errors.nominalVolumes
        }
        onValueChange={(values) => {
          handleVolumeChange(values.value);
        }}
      />
    </ListItem>
  );
}
