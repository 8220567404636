import React, { useState } from 'react';
import {
  Box, CircularProgress, Dialog, List, ListItem, Paper, Typography,
} from '@mui/material';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import useGlobal from 'global-state/store';
import { collection, orderBy, query } from 'firebase/firestore';
import { notificationItemFactory, notificationDetailFactory } from './notificationFactory';

export default function Notifications() {
  const [globalState] = useGlobal();
  return (
    <Paper sx={{
      p: 3,
    }}
    >
      {!globalState.activeOrganization && (<CircularProgress />)}
      {globalState.activeOrganization && (<NotificationsList />)}
    </Paper>
  );
}

function NotificationsList() {
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [globalState] = useGlobal();
  const firestore = useFirestore();

  const notificationsRef = collection(
    firestore,
    `organizations/${globalState.activeOrganization}/apps/digitank-gauger/notifications`,
  );
  const notificationsQuery = query(notificationsRef, orderBy('dateAndTime', 'desc'));
  const { status, data: notifications } = useFirestoreCollectionData(notificationsQuery);

  if (status === 'loading') {
    return <CircularProgress />;
  }

  const handleOpenDetail = (notification) => {
    setSelectedNotification(notification);
  };

  const handleCloseDetail = () => {
    setSelectedNotification(null);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography variant="h6">
        Notifications
      </Typography>
      <List sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {notifications.map((notification) => {
          const NotificationItem = notificationItemFactory(notification);
          return (
            <ListItem
              sx={{
                p: 0,
              }}
              key={notification.NO_ID_FIELD}
              button
              onClick={() => handleOpenDetail(notification)}
            >
              {NotificationItem}
            </ListItem>
          );
        })}
      </List>

      <Dialog open={selectedNotification !== null} onClose={handleCloseDetail}>
        {selectedNotification && notificationDetailFactory(selectedNotification, handleCloseDetail)}
      </Dialog>
    </Box>
  );
}
