import React, {
  useState, useMemo,
} from 'react';
import { useFunctions } from 'reactfire';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import { Button } from '@mui/material';
import SendCertificatesToClients from './SendCertificatesToClients';

export default function SelectedCertificatesActions({ selectedCertificates }) {
  const [, globalActions] = useGlobal();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const { t } = useTranslation();
  const [sendCertificatesDialogOpen, setSendCertificatesDialogOpen] = useState(false);

  const isValidCertificates = useMemo(() => {
    if (!selectedCertificates.length) return false;

    // Check if all certificates have type 'gauged-container'
    const allAreGaugedContainers = selectedCertificates.every((cert) => cert.type === 'gauged_container');

    return allAreGaugedContainers;
  }, [selectedCertificates]);

  const handleSendCertificate = async () => {
    if (!isValidCertificates) {
      globalActions.setSnackbarMessage({ message: t('selected_certificates.info'), severity: 'warning' });
      return;
    }
    setSendCertificatesDialogOpen(true);
  };

  return (
    <>
      <Button
        disabled={!selectedCertificates.length}
        color="success"
        variant="contained"
        onClick={handleSendCertificate}
        endIcon={<SendIcon />}
      >
        {t('send')}
      </Button>
      <SendCertificatesToClients
        open={sendCertificatesDialogOpen}
        handleClose={() => setSendCertificatesDialogOpen(false)}
        selectedCertificates={selectedCertificates}
        successCallback={() => {
          globalActions.setSnackbarMessage({ message: t('success'), severity: 'success' });
          setSendCertificatesDialogOpen(false);
        }}
      />
    </>
  );
}
