import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  where,
} from 'firebase/firestore';
import { useUser } from 'reactfire';
import CircularProgress from '@mui/material/CircularProgress';
import useGlobal from 'global-state/store';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import OurPagination from 'components/OurPagination';
import moment from 'moment';
import TankDetail from './TankDetail';
import TankListHeaders from './TankListHeaders';
import TankListItem from './TankListItem';
import TankFilters from './TankFilters';
import ClientsSelect from '../ClientsSelect';

export default function Tanks() {
  const { t } = useTranslation();
  const analytics = getAnalytics();
  const { data: user } = useUser();
  const [globalState] = useGlobal();
  const [currentCellar, setCurrentCellar] = useState(undefined);
  const [clientOrg, setClientOrg] = useState(Object.keys(globalState.clientOrganizations)[0]);

  const computeListOfConditions = useCallback((formikValues) => {
    const conditions = [
      where('lastModified', '<=', formikValues.endDate.toDate()),
      where('lastModified', '>=', formikValues.startDate.toDate()),
    ];
    return conditions;
  }, []);

  const formikInitValues = useMemo(() => ({
    cellar: '',
    startDate: initialStartDate(),
    endDate: initialEndDate(),
  }), []);

  const [listOfConditions, setListOfConditions] = useState(computeListOfConditions(formikInitValues));

  function initialStartDate() {
    return moment().subtract(2, 'years');
  }

  function initialEndDate() {
    return moment().endOf('day');
  }

  useEffect(() => {
    if (globalState.activeOrganization !== '') {
      logEvent(analytics, 'loading_gauged_tanks', {
        user_uid: user?.uid,
        appName: 'Digitank, Gauger',
        organization: globalState.activeOrganization,
      });
    }
  }, [analytics, globalState.activeOrganization, user?.uid]);

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center',
      }}
    >
      <Typography variant="h5" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('admin.client_cellar_tanks')}
      </Typography>
      <ClientsSelect updateCallback={(newOrg) => setClientOrg(newOrg)} />
      {clientOrg && (
        <OurPagination
          displayEmpty={!currentCellar}
          collectionPath={`organizations/${clientOrg}/apps/digitank-gauged-containers`
             + `/cellars/${currentCellar}/tanks`}
          listOfConditions={listOfConditions}
          orderByAttribute="lastModified"
          filters={(
            <TankListFilters
              formikInitValues={formikInitValues}
              setListOfConditions={setListOfConditions}
              computeListOfConditions={computeListOfConditions}
              setCurrentCellar={setCurrentCellar}
              clientOrg={clientOrg}
            />
          )}
          list={(
            <TankList listOfConditions={listOfConditions} currentCellar={currentCellar} />
          )}
        />
      )}
      {clientOrg === undefined && <CircularProgress />}
    </Box>
  );
}

function TankList({
  docs, initSearch, listOfConditions, currentCellar,
}) {
  const [activeTank, setActiveTank] = useState('');
  const [open, setOpen] = useState(false);
  const [tanks, setTanks] = useState([]);
  const [tanksPath, setTanksPath] = useState({});

  const refresh = useCallback(async () => {
    const allTanks = [];
    const allTanksPaths = {};

    for (const tankDoc of docs) {
      const data = { ...tankDoc.data(), cellar: currentCellar };
      allTanks.push(data);
      allTanksPaths[tankDoc.data().id + data.cellar] = tankDoc.ref.path;
    }
    setTanks(allTanks);
    setTanksPath(allTanksPaths);
  }, [currentCellar, docs]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const handleOpen = (tank) => {
    setActiveTank(tank);
    setOpen(true);
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%', gap: 1,
    }}
    >
      <TankListHeaders />
      <Paper elevation={0} sx={{ p: 1 }}>
        <List dense sx={{ width: '100%' }}>
          {tanks.map((tank, i) => (
            <div key={tank.id}>
              <TankListItem index={i} tank={tank} handleOpen={handleOpen} />
              <Divider />
            </div>
          ))}
        </List>
      </Paper>
      <TankDetail
        open={open}
        setOpen={setOpen}
        tank={activeTank}
        tankPath={tanksPath[activeTank.id + activeTank.cellar]}
        tankDeleteCallback={() => initSearch(listOfConditions)}
      />
    </Box>
  );
}

function TankListFilters({
  initSearch, formikInitValues, setListOfConditions,
  computeListOfConditions, setCurrentCellar, clientOrg,
}) {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    cellar: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: formikInitValues,
    validationSchema,
    onSubmit: (values) => {
      const newConditions = computeListOfConditions(values);
      setListOfConditions(newConditions);
      initSearch(newConditions);
    },
  });

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%',
    }}
    >
      <TankFilters
        defaultCellar
        clientOrg={clientOrg}
        formik={formik}
        datePickersLabel={t('admin.last_modified')}
        selectedCellarCallback={(cellar) => setCurrentCellar(cellar)}
      />
    </Box>
  );
}
