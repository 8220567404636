import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import CellarSelect from './CellarSelect';

export default function TankFilters({
  formik, datePickersLabel = '',
  selectedCellarCallback = () => {},
  defaultCellar = false,
  clientOrg,
}) {
  const { t } = useTranslation();

  const handleStartDateChange = (newValue) => {
    const newDate = newValue;
    if (!newDate.isSame(formik.values.startDate)) {
      if (newDate.isAfter(formik.values.endDate)) {
        const newEndDate = newDate.clone().endOf('day');
        formik.setFieldValue('endDate', newEndDate);
      }
      formik.setFieldValue('startDate', newDate);
    }
  };

  const handleEndDateChange = (newValue) => {
    const newDate = newValue;
    if (!newDate.isSame(formik.values.endDate)) {
      if (newDate.isBefore(formik.values.startDate)) {
        const newStartDate = newDate.clone().startOf('day');
        formik.setFieldValue('startDate', newStartDate);
      }
      formik.setFieldValue('endDate', newDate);
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        width: '100%',
        display: 'flex',
        gap: 2,
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <CellarSelect
        clientOrg={clientOrg}
        defaultCellar={defaultCellar}
        isCellarValid={formik.isValid}
        selectCallback={(cellar) => {
          formik.setFieldValue('cellar', cellar);
          selectedCellarCallback(cellar);
        }}
      />
      <Typography variant="body2">
        {datePickersLabel}
        {' '}
        :
      </Typography>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
        <DatePicker
          label={t('admin.between_start_date')}
          format="DD/MM/YYYY"
          value={formik.values.startDate}
          onChange={handleStartDateChange}
          slotProps={{
            textField: {
              error: formik.errors.startDate && formik.touched.startDate,
              onBlur: () => {
                formik.setFieldTouched('startDate', true);
              },
            },
          }}
        />
        <DatePicker
          label={t('admin.between_end_date')}
          value={formik.values.endDate}
          format="DD/MM/YYYY"
          onChange={handleEndDateChange}
          slotProps={{
            textField: {
              error: formik.errors.endDate && formik.touched.endDate,
              onBlur: () => {
                formik.setFieldTouched('endDate', true);
              },
            },
          }}
        />
      </LocalizationProvider>
      <Button
        disabled={!formik.isValid}
        type="submit"
        size="small"
        variant="contained"
        color="primary"
      >
        {t('apply_filters')}
      </Button>
    </Paper>
  );
}
