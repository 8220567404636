import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import FileDropzone from 'components/FileDropzone';

export default function CertificateDropZone(props) {
  const { formik } = props;
  const { t } = useTranslation();

  const callback = (acceptedFiles) => {
    const file = acceptedFiles[0];
    fileToBase64(file).then((data) => {
      formik.setFieldValue('originalPdfCertificate', data);
      formik.setFieldValue('pdfFileName', file.path);
    });
  };

  function fileToBase64(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = (event) => {
        resolve(event.target.result);
      };

      // Convert data to base64
      reader.readAsDataURL(file);
    });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 2,
        mt: 5,
      }}
    >
      <Typography variant="h5" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('certificate_creation.tank_certificate_pdf_dropzone')}
      </Typography>
      <FileDropzone
        text={t('certificate_creation.upload_pdf_text')}
        callback={callback}
        accept={{ 'application/pdf': ['.pdf'] }}
        fileName={formik.values.pdfFileName}
      />
    </Box>
  );
}
