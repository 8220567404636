import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { NumericFormat } from 'react-number-format';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ClientAutocomplete from 'components/admin/ClientAutocomplete';
import moment from 'moment';

export default function CertificateInfoInputs({
  formik, context = 'creation', type, acceptedHeightUnits, acceptedVolumeUnits,
}) {
  const { t } = useTranslation();

  return (
    <Paper>
      <Box
        elevation={0}
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 3,
          justifyContent: 'space-around',
          alignItems: 'flex-start',
        }}
      >
        <TextField
          disabled={['updateGaugingData', 'updateInfo'].includes(context)}
          id="certificateName"
          name="certificateName"
          autoComplete="off"
          variant="outlined"
          label={t('certificate_creation.certificate_number')}
          value={formik.values.certificateName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.certificateName && formik.touched.certificateName}
          helperText={
            formik.touched.certificateName
            && formik.errors.certificateName
          }
        />
        <ClientAutocomplete
          inputValue={formik.values.ownerOrganizationName}
          updateCallback={(orgName) => {
            formik.setFieldValue('ownerOrganizationName', orgName);
          }}
          error={formik.errors.ownerOrganizationName && formik.touched.ownerOrganizationName}
          helperText={
            formik.touched.ownerOrganizationName
            && formik.errors.ownerOrganizationName
          }
        />
        <TextField
          disabled={context === 'updateGaugingData'}
          id="tankName"
          name="tankName"
          autoComplete="off"
          variant="outlined"
          label={t('certificate_creation.tank_id')}
          value={formik.values.tankName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.tankName && formik.touched.tankName}
          helperText={
            formik.touched.tankName
            && formik.errors.tankName
          }
        />
        <TextField
          disabled={context === 'updateGaugingData'}
          id="cellarName"
          name="cellarName"
          autoComplete="off"
          variant="outlined"
          label={t('certificate_creation.cellar_id')}
          value={formik.values.cellarName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.cellarName && formik.touched.cellarName}
          helperText={
            formik.touched.cellarName
            && formik.errors.cellarName
          }
        />
        {context !== 'updateInfo' && (
          <NumericFormat
            id="capacity"
            name="capacity"
            label={t('certificate_creation.tank_capacity')}
            className="TextFieldBackground"
            variant="outlined"
            autoComplete="off"
            valueIsNumericString
            allowNegative={false}
            value={formik.values.capacity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            allowedDecimalSeparators={['.', ',']}
            decimalSeparator="."
            type="text"
            inputProps={{ inputMode: 'decimal' }}
            customInput={TextField}
            error={formik.errors.capacity && formik.touched.capacity}
            helperText={
              formik.touched.capacity
              && formik.errors.capacity
            }
            onValueChange={(values) => {
              formik.handleChange({
                target: {
                  name: 'capacity',
                  value: values.value,
                },
              });
            }}
          />
        )}
        {type !== 'tanker_truck' && context !== 'updateInfo' && (
          <NumericFormat
            id="nominalVolumes"
            name="nominalVolumes"
            label={t('certificate_creation.nominal_volume')}
            className="TextFieldBackground"
            variant="outlined"
            autoComplete="off"
            valueIsNumericString
            allowNegative={false}
            value={formik.values.nominalVolumes}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            allowedDecimalSeparators={['.', ',']}
            decimalSeparator="."
            type="text"
            inputProps={{ inputMode: 'decimal' }}
            customInput={TextField}
            error={formik.errors.nominalVolumes && formik.touched.nominalVolumes}
            helperText={
              formik.errors.nominalVolumes
              && formik.touched.nominalVolumes
              && formik.errors.nominalVolumes
            }
            onValueChange={(values) => {
              formik.handleChange({
                target: {
                  name: 'nominalVolumes',
                  value: values.value,
                },
              });
            }}
          />
        )}
        <TextField
          id="gaugingProviderName"
          name="gaugingProviderName"
          autoComplete="off"
          variant="outlined"
          label={t('certificate_creation.gauging_company_pdf')}
          value={formik.values.gaugingProviderName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.gaugingProviderName && formik.touched.gaugingProviderName}
          helperText={
            formik.touched.gaugingProviderName
            && formik.errors.gaugingProviderName
          }
        />

        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
          <DatePicker
            sx={{ width: 210 }}
            name="gaugeDate"
            id="gaugeDate"
            format="DD/MM/YYYY"
            label={t('certificate_creation.certificate_date')}
            value={formik.values.gaugeDate}
            onChange={(val) => {
              formik.setFieldValue('gaugeDate', val);
              const expirationYears = type === 'tanker_truck' ? 6 : 10;
              const newExpirationDate = moment(val).add(expirationYears, 'years');
              formik.setFieldValue('expirationDate', newExpirationDate);
              formik.setFieldTouched('gaugeDate', true);
              formik.setFieldTouched('expirationDate', true);
              setTimeout(() => formik.validateForm(), 0);
            }}
            slotProps={{
              textField: {
                error: formik.errors.gaugeDate && formik.touched.gaugeDate,
                onBlur: () => {
                  formik.setFieldTouched('gaugeDate', true);
                  formik.setFieldTouched('expirationDate', true);
                  setTimeout(() => formik.validateForm(), 0);
                },
              },
            }}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
          <DatePicker
            sx={{ width: 250 }}
            name="expirationDate"
            id="expirationDate"
            format="DD/MM/YYYY"
            label={t('admin.validity_date')}
            value={formik.values.expirationDate}
            onChange={(val) => {
              formik.setFieldValue('expirationDate', val);
            }}
            slotProps={{
              textField: {
                error: formik.errors.expirationDate && formik.touched.expirationDate,
                onBlur: () => {
                  formik.setFieldTouched('expirationDate', true);
                },
              },
            }}
          />
        </LocalizationProvider>

        {context !== 'updateInfo' && (
        <FormControl sx={{ backgroundColor: 'white', minWidth: 195 }}>
          <InputLabel id="height-unit-select-label">
            {t('certificate_creation.height_unit')}
          </InputLabel>
          <Select
            id="heightUnit"
            name="heightUnit"
            autoComplete="off"
            variant="outlined"
            label={t('certificate_creation.height_unit')}
            value={formik.values.heightUnit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {acceptedHeightUnits.map((unit, i) => (
              <MenuItem value={unit} key={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>
          {formik.errors.heightUnit
          && formik.errors.heightUnit && (
            <Typography variant="caption" display="block" color="red">
              {formik.errors.heightUnit}
            </Typography>
          )}
        </FormControl>
        )}
        {context !== 'updateInfo' && (
        <FormControl sx={{ backgroundColor: 'white', minWidth: 195 }}>
          <InputLabel id="volume-unit-select-label">
            {t('certificate_creation.volume_unit')}
          </InputLabel>
          <Select
            id="volumeUnit"
            name="volumeUnit"
            autoComplete="off"
            variant="outlined"
            label={t('certificate_creation.volume_unit')}
            value={formik.values.volumeUnit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {acceptedVolumeUnits.map((unit, i) => (
              <MenuItem value={unit} key={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>
          {formik.errors.volumeUnit
            && formik.errors.volumeUnit && (
              <Typography variant="caption" display="block" color="red">
                {formik.errors.volumeUnit}
              </Typography>
          )}
        </FormControl>
        )}
      </Box>
    </Paper>
  );
}
