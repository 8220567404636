import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import useGlobal from 'global-state/store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useFirestore,
} from 'reactfire';
import {
  doc, updateDoc, arrayUnion, deleteDoc,
} from 'firebase/firestore';

function ContactGaugerItem({ notification }) {
  const { t } = useTranslation();
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', border: 1, borderRadius: 1, p: 1,
    }}
    >
      <Typography>
        {`${t(`notifications.${notification.notificationType}Text`)} : ${notification.fromOrganizationName}`}
      </Typography>
    </Box>
  );
}

function ContactGaugerDetail({ notification, handleCloseDetail }) {
  const firestore = useFirestore();
  const [globalState] = useGlobal();
  const { t } = useTranslation();

  const handleDelete = async () => {
    await deleteDoc(doc(
      firestore,
      `organizations/${globalState.activeOrganization}/apps/digitank-gauger/notifications`,
      notification.NO_ID_FIELD,
    ));
    handleCloseDetail();
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', p: 3, gap: 2,
    }}
    >
      <Typography>
        {`${t(`notifications.${notification.notificationType}Text`)} : ${notification.fromOrganizationName}`}
      </Typography>
      <Typography>
        {notification.message}
      </Typography>
      <Box sx={{
        display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, justifyContent: 'flex-end',
      }}
      >
        <LoadingButton variant="contained" color="primary" onClick={handleDelete}>
          {t('delete')}
        </LoadingButton>
      </Box>
    </Box>
  );
}

function CollaborationInvitationItem({ notification }) {
  const { t } = useTranslation();
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', border: 1, borderRadius: 1, p: 1,
    }}
    >
      <Typography>
        {`${t(`notifications.${notification.notificationType}Text`)} : ${notification.organizationName}`}
      </Typography>
    </Box>
  );
}

function CollaborationInvitationDetail({ notification, handleCloseDetail }) {
  const firestore = useFirestore();
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();

  const appRef = doc(firestore, `organizations/${globalState.activeOrganization}/apps/digitank-gauger`);

  const handleAccept = async () => {
    await updateDoc(appRef, {
      organizationCollaborations: arrayUnion(notification.organizationId),
    });
    await deleteDoc(doc(
      firestore,
      `organizations/${globalState.activeOrganization}/apps/digitank-gauger/notifications`,
      notification.NO_ID_FIELD,
    ));
    globalActions.setClientOrganizations({
      ...globalState.clientOrganizations,
      [notification.organizationId]: notification.organizationName,
    });
    handleCloseDetail();
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', p: 3, gap: 2,
    }}
    >
      <Typography>
        {`${t(`notifications.${notification.notificationType}Text`)} : ${notification.organizationName}`}
      </Typography>
      <Box sx={{
        display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, justifyContent: 'flex-end',
      }}
      >
        <LoadingButton variant="contained" color="primary" onClick={handleAccept}>
          {t('accept')}
        </LoadingButton>
      </Box>
    </Box>
  );
}

export function notificationItemFactory(notification) {
  switch (notification.notificationType) {
    case 'collaborationInvitation':
      return <CollaborationInvitationItem notification={notification} />;
    case 'newContactGauger':
      return <ContactGaugerItem notification={notification} />;
    default:
      return <Typography>{`Unknown type : ${notification.notificationType}`}</Typography>;
  }
}

export function notificationDetailFactory(notification, handleCloseDetail) {
  switch (notification.notificationType) {
    case 'collaborationInvitation':
      return <CollaborationInvitationDetail notification={notification} handleCloseDetail={handleCloseDetail} />;
    case 'newContactGauger':
      return <ContactGaugerDetail notification={notification} handleCloseDetail={handleCloseDetail} />;
    default:
      return <Typography>{`Unknown type : ${notification.notificationType}`}</Typography>;
  }
}
